import request from '../Axios'

// 列表
export const queryPage = data => {
  return request({
    // url: '/activity/business/query/page',
    url: '/template/appointment/query/page',
    method: 'get',
    params: data
  })
}

// 签到 or 备注
export const businessUpdate = data => {
  return request({
    // url: '/activity/business/update',
    url: '/template/appointment/update',
    method: 'put',
    data
  })
}

// 作废
export const businessDelete = data => {
  return request({
    // url: `/activity/business/delete/${data.id}`,
    url: `/template/appointment/delete/${data.id}`,
    method: 'delete',
    params: {
      type: data.type
    }
  })
}

// 导出记录
export const businessExport = data => {
  return request({
    url: '/template/appointment/export',
    // url: '/activity/business/export',
    method: 'get',
    params: data,
    responseType: 'blob'
  })
}
